.DefaultWelcome {
  text-align: center;
}

.image {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .image {
    animation: DefaultWelcome-logo-spin infinite 20s linear;
  }
}

.DefaultWelcome-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: rgba(0, 0, 0, 0.8);
}

@keyframes DefaultWelcome-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
