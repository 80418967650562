.Error {
  text-align: center;
}

.error-image {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .error-image {
    //animation: Error-logo-spin infinite 20s linear;
  }
}

.Error-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: rgba(0, 0, 0, 0.8);
}

@keyframes Error-logo-spin {
  //from {
  //  transform: rotate(0deg);
  //}
  //to {
  //  transform: rotate(360deg);
  //}
}
