.white-link {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;

  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
}

.app-name {
  color: white;
  text-decoration: none;

  &:hover {
    color: #f1f1f1;
  }
}
